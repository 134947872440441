.nav-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.nav-icon {
    position: relative;
    height: 40px;
    width: 40px;
    overflow: hidden;
    display: block;
    padding: 0;
    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        color: $thrive-black;
    }
    .show & {
        background-color: $thrive-white;
    }
    .fas,
    svg {
        font-size: 20px;
    }

    &.action.showcart {
        white-space: unset;
        &:before {
            .minicart-wrapper & {
                font-size: unset;
                line-height: unset;
                color: initial;
                content: '';
                font-family: unset;
                margin: 0;
                vertical-align: unset;
                display: none;
                font-weight: normal;
                overflow: unset;
                speak: none;
                text-align: inherit;
            }
        }
        &.active {
            display: flex;
        }
    }
}

.nav-icon-style {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    font-size: 10px;
    font-weight: 500;

    &.text-transition {
        transition: transform 0.4s ease;
        @media (pointer: coarse) {
            transition: none;
        }
    }
    &.text-show {
        transform: translateX(-40px);
        @media (pointer: coarse) {
            transform: none;
        }
    }
}

.nav-icon-text {
    margin-left: unset;
    right: -40px;
}

.nav-icon-icon {
    left: 0;
    .fa-truck {
        transform: scale(-1,1);
    }
}

.cart-qty {
    position: relative;
    &.counter.qty {
        .action.showcart & {
            .minicart-wrapper & {
                background: unset;
                color: $thrive-black;
                height: unset;
                line-height: initial;
                border-radius: 0;
                display: block;
                min-width: unset;
                overflow: unset;
                padding: 0;
                text-align: unset;
                margin: 0;
            }
        }
    }
    &.empty {
        .minicart-wrapper & {
            display: block;
        }
    }
}
.cart-qty-value {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 1px solid $thrive-black;
    border-radius: 50%;
    background: $thrive-white;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    line-height: 8px;
    .empty &,
    &:empty {
        display: none;
    }
}

.account-icon {
    svg {
        font-size: 22px;
    }
    .dropdown-header {
        border-bottom: 1px solid $thrive-light-gray;
        margin-bottom: 0.325rem;
    }
    .dropdown-toggle:after {
        display: none;
    }
    .nav-icon-text > svg {
        display: none;
    }
}

.mobile-search {
    height: 50px;
    border-radius: 0;
    border: none;
    display: flex;
    align-items: center;
    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        color: $thrive-black;
        text-decoration: none;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
    svg,
    & {
        font-size: 20px;
        color: $thrive-black;
    }
    &.active {
        background: $thrive-light-gray;
    }
}

.search_mini_form {
    @include media-breakpoint-down(md) {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        padding: 1rem 0.5rem;
        background: $thrive-light-gray;
        .search-text {
            border: $thrive-light-gray;
        }
    }
}
