#kb-s-form {
    display: flex;
}
.kb__search-box {
    margin-bottom: 2.5rem;
}

.kb-category__grid {
    display: block;
    margin-top: 2rem;
    .kb__category {
        width: 33%;
        padding-right: 3rem;
        display: inline-grid;
        ol {
            padding-left: inherit;
            list-style: none;
            li {
                padding-bottom: 4px;;
            }
        }
    }
}

// Article List
.kb-articles__list {
    list-style: none;
    padding-left: 0px;
}

.kb-articles__article{
    margin-bottom: 1rem;
    a{
        font-weight: 600;
        font-size: 1.8rem;
    }
}

// Article
.kb-article__metadata {
    display: flex;
    margin-bottom: 1rem;
    .author {
        color: #a3a3a3;
        float: left;
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        margin-right: 2rem;
    }
    .rating {
        float: left;
        color: #a3a3a3;
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        min-width: 25px
    }
}

.kb-article__metadata-list {
    display: flex;

    .author {
        color: #a3a3a3;
        float: left;
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        margin-right: 2rem;
    }
    .rating {
        float: left;
        color: #a3a3a3;
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        min-width: 25px
    }
}

.kb-article__body-info-left {
    float: left;
    width: 60%;
}

.kb-article__body-info-right {
    float: right;
    width: 30%;
    min-width: 250px;
    .kb-article__helpful{
        .metadata{
            font-size: 14px;
            float: left;
            strong {
                display: block;
            }
        }
        a {
        display: block;
        border: 1px solid #c2c2c2;
        color: #c2c2c2;
        width: 35px;
        height: 35px;
        border-radius: 3px;
        margin-left: 5px;
        float: left;
        font-size: 20px;
            i {
                padding: 8px;
            }
        }
    }
}

.kb-article__body{
    margin-bottom: 1rem;
}

.kb-article__categories {
    margin-bottom: 1rem;
    span:after {
        content: '';
        margin: 0 0.5rem;
    }
}
.kb-article__tags{
    margin-bottom: 1rem;
    span:after {
        content: '';
        margin: 0 0.5rem;
    }
}

#block-collapsible-nav-kb {
    background: #f5f5f5;
    padding: 15px 0;
    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none none;
        a{
            padding: 4px 40px 4px 24px;
            position: relative;
            display: block;
        }
        li.level-2 a{
            margin-left: 1rem;
        }
        li.level-3 a{
            margin-left: 2rem;
        }
        li.level-4 a{
            margin-left: 3rem;
        }

        .collapsible-control {
            left: 2px;
            top: 0px;
            z-index: 1000;
            cursor: pointer;
            position: absolute;
            &.active::after {
                content: "\e621";
                font-family: "icons-blank-theme";
                font-size: x-large;
            }
            &.hidden {
                display: block;
                &::after {
                    content: "\e622";
                    font-family: "icons-blank-theme";
                    font-size: x-large;
                }
            }
        }
        .count {
            color: #a3a3a3;
            position: absolute;
            top: 5px;
            right: 18px;
        }
    }
}