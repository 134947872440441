// Based on Bootstrap 4's .btn base class
a.action.primary:not(.btn),
button:not(.btn):not(.close) {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    @include hover() {
        color: $body-color;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Similar to .btn-default in Bootstrap 3
button.action:not(.primary):not(.btn),
.btn-default {
    @include button-variant(map-get($theme-colors, 'light'), $thrive-medium-gray);
}

// Based on .btn-link
a.action:not(.primary):not(.btn) {
    font-weight: $font-weight-normal;
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover() {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    & + & {
        margin-left: 1rem;
    }
}

// Similar to .btn-primary
a.action.primary:not(.btn),
button.primary:not(.btn) {
    @include button-variant(map-get($theme-colors, 'primary'), map-get($theme-colors, 'primary'));
}

.field .control select {
    @extend .form-control;
}

// Fix TL custom radio checked state
.custom-radio .custom-control-input:checked ~ .custom-control-label {
    &::before {
        border-color: $custom-control-indicator-border-color;
    }
    &::after {
        background-size: calc(100% - 2px) calc(100% - 2px);
    }
}
