// ----------------------------------------
// Thrive Theme Overrides
// ----------------------------------------

// ----------------------------------------
// Cart Summary
// ----------------------------------------

.cart {
    &-container {
        display: flex;
        flex-direction: column;
        @include min-screen($screen__m) {
            flex-direction: row;
        }
    }
    &-summary {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 2rem;
        &-container {
            @include min-screen($screen__m) {
                order: 2;
                width: 350px;
                margin-left: 2rem;
                margin-bottom: 0;
            }
        }
        .table-wrapper {
            margin-bottom: 0;
        }
        .checkout-methods-items {
            margin-top: 2rem;
        }
    }

    &.table-wrapper {
        h2 {
            margin: 1rem 0;
            font-weight: 300;
        }
    }

    &-totals {
        .mark {
            background-color: #fff;
            font-weight: normal;
        }
        .table {
            th,
            td {
                padding: 1rem 0;
                vertical-align: middle;
            }
            .amount {
                white-space: nowrap;
                text-align: right;
                padding-left: 0.5rem;
            }
        }
        .grand.totals {
            font-size: 1.15rem;
            strong {
                font-weight: 500;
            }
        }
    }
}

.form-cart {
    @include min-screen($screen__m) {
        flex: 1;
    }
}

.summary {
    &.title {
        font-size: 2rem;
        font-weight: 300;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        display: block;
    }
}

.block {
    &.shipping,
    &.discount {
        margin-bottom: 1rem;
        .title {
            margin-bottom: 0;
            > strong {
                .column.main & {
                    font-size: 1rem;
                }
                font-weight: normal;
                color: $thrive-blue-contrast;
                display: block;
                position: relative;
                cursor: pointer;
                margin: 1rem 0;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    text-decoration: underline;
                    outline: none;
                }
                &:after {
                    position: absolute;
                    top: 1;
                    right: 0;
                    vertical-align: middle;
                    font-family: "Font Awesome 5 Free";
                    content: "\f078";
                    font-weight: 900;
                    font-size: 1rem;
                    line-height: inherit;
                    color: $thrive-blue-contrast;
                    overflow: hidden;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                }
            }
        }
        &.active {
            .title {
                strong:after {
                    content: "\f077";
                }
            }
        }
    }
    &.shipping {
        .content {
            margin: 1rem 0;
        }
    }
}

.cart-summary {
    form {
        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
        .fieldset {
            &.estimate {
                .legend {
                    display: none;
                }
            }
            > .field {
                margin-bottom: 1rem;
                &:not(.choice) {
                    > .label,
                    > .control {
                        float: unset;
                        width: 100%;
                        text-align: left;
                    }
                    > .label {
                        padding: 0 0 0.5rem 0;
                    }
                }
                &.note {
                    margin-top: -1rem;
                }
            }
        }
        .field.choice.item {
            display: flex;
            input {
                margin-right: 1rem;
            }
        }
    }
    .checkout.action {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
    }
}

// ----------------------------------------
// Cart Items
// ----------------------------------------

.cart {
    &-item {
        border-bottom: 1px solid $thrive-light-gray;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: stretch;
        &:first-of-type {
            border-top: 1px solid $thrive-light-gray;
            padding-top: 1rem;
        }
        &-photo {
            display: block;
            margin-right: 1rem;
            width: 50px;
            @include min-screen($screen__l) {
                margin-right: 2rem;
                width: 55px;
            }
            > img {
                @include min-screen($screen__m) {
                    max-height: 80px;
                }
            }
        }
        &-info {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        &-details {
            flex: 1;
            .price-including-tax,
            .price-excluding-tax {
                font-size: 1rem;
                .price {
                    font-weight: normal;
                    color: rgba(0, 0, 0, 0.34);
                    &:after {
                        content: " each";
                    }
                }
            }
        }
        &-name {
            font-size: 1.15rem;
            font-weight: 500;
            a {
                &,
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: $thrive-green;
                }
            }
        }
        &-options {
            margin: 0.25rem 0;
            dt {
                display: none;
            }
            dd {
                margin-bottom: 0;
            }
        }
        &-actions {
            margin-top: 1rem;
            font-size: 0.75rem;
            .actions-toolbar {
                display: flex;
                align-items: center;
                form & {
                    .column:not(.sidebar-main) & {
                        margin-left: 0;
                    }
                }
                a {
                    padding: 0.325rem 0.75rem;
                    margin-right: 0.5rem;
                }
            }
            .action-edit {
                display: none;
            }
        }
        &-qty {
            margin-right: 0.325rem;
            font-size: 0.875rem;
            position: relative;
            &:before {
                content: "Qty";
                position: absolute;
                top: 7px;
                left: 12px;
                pointer-events: none;
            }
            &.cart-item-input-active {
                &:before {
                    content: "";
                    position: static;
                }
            }

            &-select {
                &.custom-select {
                    padding-left: 2.3rem;
                    padding-right: 1rem;
                    width: 73px;
                    .cart-item-input-active & {
                        display: none;
                    }
                }
            }
            label {
                margin: 0;
            }
            &-input {
                &.form-control {
                    display: none;
                    &[type="number"] {
                        width: 73px;
                        font-size: 0.875rem;
                    }
                    .cart-item-input-active & {
                        display: block;
                    }
                }
            }
        }
        &-subtotal {
            margin-left: 1rem;
            text-align: right;
            white-space: nowrap;
            @include min-screen($screen__l) {
                margin-left: 2rem;
            }
            .price-including-tax,
            .price-excluding-tax {
                font-size: 1rem;
                .price {
                    font-weight: 500;
                }
            }
        }

        .cart-delivery-item-text {
            color: #f6a81c;
        }
    }
}

.checkout-cart-index {
    .page-title {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.cart.main.actions {
    text-align: right;
    margin-bottom: 2rem;
    .action {
        display: block;
        width: 100%;
        margin-bottom: 0.5rem;
        @include min-screen($screen__m) {
            display: inline-block;
            width: auto;
        }
    }
}
