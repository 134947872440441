//
//  Variables
//  _____________________________________________

$checkout-progress-bar__font-size                              : 1rem !default;
$checkout-progress-bar__font-weight                            : $font-weight__light !default;
$checkout-progress-bar__margin                                 : $indent__base !default;

$checkout-progress-bar-item__background-color                  : $color-gray-middle1 !default;
$checkout-progress-bar-item__border-radius                     : 1px !default;
$checkout-progress-bar-item__color                             : $thrive-black !default;
$checkout-progress-bar-item__margin                            : $indent__s !default;
$checkout-progress-bar-item__active__background-color          : $thrive-black !default;
$checkout-progress-bar-item__complete__color                   : $thrive-blue-contrast !default;

$checkout-progress-bar-item-element__width                     : 26px !default;
$checkout-progress-bar-item-element__height                    : $checkout-progress-bar-item-element__width !default;

$checkout-progress-bar-item-element-inner__background-color    : $page__background-color !default;
$checkout-progress-bar-item-element-inner__color               : $checkout-progress-bar-item__color !default;
$checkout-progress-bar-item-element-outer-radius__width        : 1px !default;
$checkout-progress-bar-item-element-inner__width               : $checkout-progress-bar-item-element__width - ($checkout-progress-bar-item-element-outer-radius__width * 2) !default;
$checkout-progress-bar-item-element-inner__height              : $checkout-progress-bar-item-element-inner__width !default;
$checkout-progress-bar-item-element-inner__active__content     : $icon-checkmark !default;
$checkout-progress-bar-item-element-inner__active__font-size   : 1rem !default;
$checkout-progress-bar-item-element-inner__active__line-height : 1 !default;

//
//  Checkout Progress Bar
//  ---------------------------------------------

.opc-progress-bar {
    @extend .abs-reset-list;
    counter-reset: i;
    font-size: 0;
    display: flex;
    justify-content: space-between;
    margin: 0 1rem 2rem;
    @include min-screen($screen__m) {
        margin: 0 auto 2rem;
    }
    @include min-screen($screen__l) {
        width: 50%;
    }
}

.opc-progress-bar-item {
    @include lib-css(margin, 0 0 $checkout-progress-bar-item__margin);
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
    flex: 1;

    > span {
        display: inline-block;
        padding-top: 2rem;
        width: 100%;
        word-wrap: break-word;

        @include lib-typography(
            $_color       : darken($thrive-gray, 20%),
            $_font-family : false,
            $_font-size   : $checkout-progress-bar__font-size,
            $_font-style  : false,
            $_font-weight : $checkout-progress-bar__font-weight,
            $_line-height : false
        );

        &:before {
            position: absolute;
            left: 50%;
            top: 0;
            z-index: 2;
            @include lib-css(background, $thrive-white);
            @include lib-css(height, $checkout-progress-bar-item-element__height);
            @include lib-css(margin-left, -($checkout-progress-bar-item-element__width/2));
            @include lib-css(width, $checkout-progress-bar-item-element__width);
            border-radius: 50%;
            border: 1px solid $thrive-gray;
            content: '';
            content: counter(i);
            counter-increment: i;
            @include lib-typography(
                $_color       : darken($thrive-gray, 20%),
                $_font-family : false,
                $_font-size   : $checkout-progress-bar__font-size,
                $_font-style  : false,
                $_font-weight : 500,
                $_line-height : 1.45
            );
        }

        &:after {
            content: '';
            position: absolute;
            @include lib-css(top, $checkout-progress-bar-item-element__width/2);
            right: 0;
            z-index: 1;
            height: 1px;
            width: 100%;
            background-color: $thrive-gray;
        }
    }

    &:first-of-type {
        > span {
            &:after {
                width: 50%;
            }
        }
    }
    &:last-of-type {
        > span {
            &:after {
                width: 50%;
                right: unset;
                left: 0;
            }
        }
    }

    &._active {
        > span {
            font-weight: 500;
            color: $thrive-black;
            &:before {
                background: $thrive-black;
                color: $thrive-white;
                border-color: $thrive-black;
            }
        }
    }

    &._complete {
        cursor: pointer;

        > span {
            &:before {
                background: lighten($thrive-black, 64%);
                color: $thrive-white;
                border-color: lighten($thrive-black, 64%);
            }
        }
    }
}
