//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white-smoke !default;
$checkout-summary__padding          : 22px $indent__l !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__max-height : 370px !default;
$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            position: relative;
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 30px,
                $_icon-font-line-height : 12px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : 3px 0 0,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );

            &:focus {
                outline: none;
            }

            &:after {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &.active {
            > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .product {
            position: relative;
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        padding: 0;
        margin: 0;
        border: 0;
        @include lib-css(max-height, $checkout-summary-items__max-height);
    }

    .product-item {
        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;
                .price {
                    @include lib-font-size(10);
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}

//
//  Thrive Theme
//  _____________________________________________

.checkout-summary {
    order: 2;
    @include min-screen($screen__l) {
        width: 350px;
    }
    &-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        margin-bottom: 1rem;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
            margin-bottom: 0;
        }
    }
    .items-in-cart {
        padding-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        margin-bottom: 1rem;
        .title {
            margin-bottom: 0;
            strong {
                font-weight: $font-weight__light;
                margin: 0;
                &,
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    color: $thrive-blue-contrast;
                }
                .column.main & {
                    font-size: 1rem;
                }
            }
        }
    }
    &-cart-items {
        max-height: 275px;
        overflow-y: auto;
    }
    &-cart-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.625rem;
        border-bottom: 1px solid $thrive-light-gray;
        margin-bottom: 0.625rem;
        font-size: 0.875rem;
        &:first-of-type {
            padding-top: 1rem;
        }
        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
        &-img {
            width: 50px;
            margin-right: 1rem;
        }
        &-title {
            font-weight: 500;
        }
        &-details {
            flex: 2;
        }
        &-qty {
            margin-left: 1rem;
        }
        &-subtotal {
            margin-left: 1rem;
            min-width: 67px;
            text-align: right;
            .price-including-tax,
            .price-excluding-tax {
                font-size: 0.875rem;
                .price {
                    font-weight: normal;
                }
            }
        }
    }
    &-totals {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        @media (min-width: $screen__m) and (max-width: $screen__l) {
            justify-content: flex-end;
        }
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom-style: none;
        }
        &.grand {
            font-size: 1.18rem;
        }
        &-title {
            margin-right: 1rem;
            @media (min-width: $screen__m) and (max-width: $screen__l) {
                text-align: right;
            }
            .value {
                display: block;
            }
        }
        &-value {
            white-space: nowrap;
            text-align: right;
            @media (min-width: $screen__m) and (max-width: $screen__l) {
                width: 100px;
            }
        }
    }

}
.opc-block-summary,
.shipping-information {
    padding: 1rem;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}
