$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product-item-inner .product a.action.more{
    text-decoration: underline;
    font-size: 14px;
}

.product.name a {
    @extend .abs-product-link;
    color: #29797f;
    &:active {
        color: #29797f;
    }
    &:visited {
        color: #29797f;
    }
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product-info-main {
    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: 1rem;
    }

    .product-reviews-summary {
        margin-top: 0;
        margin-bottom: 1rem;
        .rating-summary {
            left: 0;
        }
        .reviews-actions {
            font-size: 1rem;
            a {
                margin-right: 0;
            }
        }
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            margin-bottom: $indent__xs;
            .price {
                @include lib-font-size(12);
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            @include lib-font-size(18);
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: $tocart-input-size;
            text-align: center;
            width: $tocart-input-size;
        }

        .actions {
            text-align: center;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field.qty {
                display: table-cell;
            }

            .actions {
                display: table-cell;
                padding-top: $indent__m;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 1rem 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 1rem;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 1rem;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 1rem 0;
    }

    .product-addto-links {
        margin-top: 1rem;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

//
// Styles for Thrive Life Theme
// -----------------------------------------------

.product {
    &-info {
        &-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            @include min-screen($screen__m) {
                flex-direction: row;
            }
            @include min-screen($screen__l) {
                margin-bottom: 3rem;
            }
            .page-title {
                font-size: 2.25rem;
                color: $thrive-green;
                @include max-screen($screen__m) {
                    font-size: 1.5rem;
                }
            }
        }
        &-main {
            flex: 1;
            order: 2;
            margin-bottom: 0;
        }
    }
    &.media {
        position: relative;
        @include min-screen($screen__m) {
            width: 390px;
            margin-left: 0;
            margin-right: 2rem;
        }
        @include min-screen($screen__l) {
            width: 500px;
            margin-left: 0;
            margin-right: 3rem;
        }
        @include min-screen($screen__xl) {
            width: 600px;
        }
    }
    &-options {
        &-wrapper {
            margin-top: 1.5rem;
        }
        &-selects {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            label {
                font-weight: bold;
            }
            .form-group {
                margin-bottom: 0;
            }
            &-qty {
                display: flex;
                .size-qty {
                    width: 4rem;
                    margin-right: 1rem;
                    background: #fff;
                }
            }
            &-shipments {
                display: none;
                flex: 1;
                margin-left: 1rem;
                .delivery & {
                    display: block;
                }
                .delivery-disabled & {
                    display: none;
                }
            }
        }
        &-btns {
            margin-top: 2rem;
            .btn {
                width: 100%;
            }
            &-delivery {
                &.btn {
                    display: none;
                    .delivery & {
                        display: block;
                    }
                }
            }
            &-retail {
                &.btn {
                    display: block;
                    .delivery & {
                        display: none;
                    }
                }
            }
        }
    }

    &-detail {
        &-container {
            margin-bottom: 2rem;
            @include min-screen($screen__m) {
                margin-left: 0;
                margin-right: 0;
            }
            @include min-screen($screen__l) {
                margin-bottom: 3rem;
            }
        }

        &-section {
            border-top: 1px solid $thrive-light-gray;
            padding: 2rem 1rem;
            &:last-of-type {
                border-bottom: 1px solid $thrive-light-gray;
            }
        }

        &-heading {
            background: #fff;
            display: flex;
            align-items: center;
            cursor: pointer;

            .section-icon {
                margin-right: 1rem;
                font-size: 2rem;
            }
            .minus-icon {
                display: none;
                .open & {
                    display: block;
                }
            }
            .plus-icon {
                .open & {
                    display: none;
                }
            }
        }
        &-title {
            margin: 0;
            padding: 0;
            font-size: 24px;
            font-weight: normal;
        }

        &-collapse {
            margin-top: 2rem;
            .open & {
                display: block;
            }
            .fa-ul {
                .fa-check {
                    color: $thrive-orange;
                }
            }
        }
    }
    &-reviews {
        &-container {
            background: lighten($thrive-light-gray, 5%);
            overflow: hidden;
            padding: 2rem 0;
            position: relative;
            @include min-screen($screen__m) {
                padding: 3rem 0;
            }
        }
    }
}

.gallery-placeholder {
    flex: 1;
    @include min-screen($screen__m) {
        max-width: 390px;
    }
    @include min-screen($screen__l) {
        max-width: 500px;
    }
    @include min-screen($screen__xl) {
        max-width: 600px;
    }
}

.fotorama__nav--thumbs {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    .fotorama__thumb {
        border: 1px solid $thrive-light-gray;
    }
    .fotorama__thumb-border {
        border-color: $thrive-green;
    }
}

// Amasty Advanced Reviews Overrides
.product-reviews-container {
    .amreview {
        &-info-wrapper {
            display: flex;
            flex-wrap: wrap;
            @include min-screen($screen__m) {
                align-items: stretch;
            }
        }
        &-add-new {
            margin: 0;
            padding: 0;
            background: none;
        }
        &-summary-info {
            display: flex;
            .amreview-summary {
                float: none;
                margin-bottom: 0;
            }
            .amreview-rating-wrapper {
                float: none;
            }
        }
        &-summary-details {
            @include max-screen($screen__l) {
                flex: 1;
                min-width: 50%;
            }
        }
        &-review-list {
            &.-empty {
                background: $thrive-white;
                padding: 2rem;
                margin-bottom: 1.5rem;
                border-radius: 10px;
            }
        }
        &-review-wrapper {
            &.review-item {
                background: $thrive-white;
                padding: 2rem;
                margin-bottom: 1.5rem;
                margin-top: 0;
                border-bottom: none;
                border-radius: 10px;
            }
            .amreview {
                &-title-review {
                    font-size: 1rem;
                }
            }
        }
    }
    .amrev {
        &-title-block {
            display: block;
            .am-strong {
                padding: 0;
                font-size: 1.5rem;
                font-weight: 500;
                white-space: unset;
                margin-bottom: 0;
            }
            &.amreview-review-list {
                padding: 2.5rem 0 0 0;
                margin-top: 2.5rem;
                border-top: 1px solid $thrive-light-gray;
                .am-strong {
                    padding: 0;
                    font-size: 1.25rem;
                    font-weight: 500;
                    white-space: unset;
                    margin-bottom: 0;
                }
            }
        }
        &-toolbar-container {
            background: none;
            font-size: 1rem;
            @include min-screen($screen__m) {
                flex-wrap: nowrap;
                align-items: stretch;
            }
            .amrev {
                &-filters {
                    justify-content: flex-start;
                    align-items: center;
                    order: 0;
                }
                &-filter {
                    @include min-screen($screen__m) {
                        flex-basis: auto;
                        border-bottom: none;
                        padding: {
                            top: 0;
                            bottom: 0;
                        }
                    }
                }
                &-sorting {
                    > div {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        label {
                            margin-bottom: 0;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.product-reviews-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 3rem;
}

.product-reviews-form-container {
    .amreview-submit-form {
        background: #fff;
        margin: 0;
        padding: 1rem;
        .block-content {
            padding: 0;
            margin: 0;
        }
        .review-control-vote.control {
            &:before,
            label:before {
                font-size: 2rem;
                height: 2rem;
            }
        }
        .fieldset > .field:not(.choice) {
            > .label,
            > .control {
                float: unset;
                text-align: left;
                width: 100%;
            }
        }
        .field.review-field-nickname.-half,
        .field.review-field-email {
            float: unset;
            width: 100%;
        }
        .review-form-actions {
            display: none;
        }
    }
}

.freeze-dried {
    .page-title & {
        font-weight: normal;
        font-size: 0.75rem;
        line-height: inherit;
        color: #999;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.product-reviews-summary {
    .rating-summary {
        .rating-result {
            > span:before {
                color: $thrive-orange;
            }
        }
    }
}

.amreview-recommended {
    max-width: 6.5rem !important;
}

.amreview-recommend-wrap,
.amreview-gdpr-wrap {
    & > .amreview-checkbox {
        @extend .custom-control-input;
    }
    & > .amreview-checkboxlabel {
        padding-left: 1.5rem;
        padding-right: 0;
        &::before {
            right: auto;
            left: 0;
        }
    }
    & > .amreview-checkbox:checked ~ .amreview-checkboxlabel::after {
        left: 2px;
        right: auto;
    }
}
.products-grid {
    .product-item {
        div[data-role="priceBox"] {
            display: block;
        }
        .price-box {
            span.price-label {
                font-size: 16px;
                &::after {
                    content: '';
                }
            }
            .price {
                font-weight: normal;
                font-size: 16px;
            }
            .old-price {
                color: #bfbfbf;
                .price {
                    font-size: 14px;

                }
            }
        }
        .no-special {
            span.price-label{
                display: block;
                font-size: 14px;
                color: #bfbfbf;
            }
        }
        .has-special {
            span.price-label {
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
}
.catalog-product-view {
    .amreview-summary-info {
        flex-basis: 33%;
    }
    .amreview-add-new {
        flex-basis: auto;
        order: 3;
    }
    .amreview-info-wrapper {
        .amreview-summary-details {
            flex-basis: 45%;
            order: 2;
            border-right: none;
        }
    }
}
