//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-content {
    &-before {
        @extend .abs-add-clearfix;
    }
    &-main {
        display: flex;
        flex-direction: column;
        @include min-screen($screen__l) {
            flex-direction: row;
            margin-top: 2rem;
        }
    }
}

.opc-wrapper {
    margin-top: 2rem;
    order: 1;
    @include min-screen($screen__l) {
        margin-top: 0;
        margin-right: 2rem;
        flex: 1;
    }
    .opc {
        @extend .abs-reset-list;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    .opc-block-summary {
        .btn:first-child {
            display: none !important;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}


//
//  Thrive Theme
//  _____________________________________________

.opc-wrapper {
    .step-title {
        font-size: 1.5rem;
        font-weight: 300;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        @include min-screen($screen__l) {
            margin-top: 1rem;
        }
    }
}

// Outline each area on mobile including tablet
.opc-wrapper > .opc > li {
    @include max-screen($screen__l) {
        padding: 1rem;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        margin-bottom: 1rem;
    }
}
