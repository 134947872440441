@import '../../../Magento_Checkout/styles/module/checkout/order-summary';
@import '../../../Magento_Checkout/styles/module/checkout/payments';

.page-title-wrapper {
    margin-top: 2rem;
}

.block {
    &.discount {
        padding: 1rem 0;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        .payment-option-content & {
            margin: 1rem 0;
        }
    }
}

.opc-block-summary {

    .items-in-cart {
        > .title {
            cursor: initial;
            &:after {
                display: none;
            }
            strong {
                color: inherit;
            }
        }
        .product {
            &-item {
                &-name {
                    font-weight: 500;
                }
                &-photo {
                    width: 50px;
                    margin-right: 1rem;
                }
            }
        }
        .item-options {
            dt {
                display: none;
            }
            dd {
                font-weight: 400;
            }
        }
        .qty {
            margin-left: 1rem;
            white-space: nowrap;
            &:before {
                content: 'Qty: ';
            }
        }
        .price-excluding-tax {
            font-size: 1rem;
            display: none;
            .price {
                font-weight: 400;
            }
            &:last-of-type {
                display: block;
                text-align: right;
                margin-left: 1rem;
            }
        }
    }
}

