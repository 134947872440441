@import '../../../styles/tl/variables';

//
//  Thrive Styles
//  _____________________________________________

.minicart {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1rem;
    width: 80%;
    background: $thrive-white;
    z-index: 1020;
    backdrop-filter: blur(10px) saturate(180%);
    overflow: hidden;
    transform: translateX(120%);
    @include media-breakpoint-up(md) {
        width: 24rem;
    }
    .cart-transition & {
        transition: transform 0.2s ease;
    }
    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1010;
        padding: 20px;
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(1px) saturate(150%);
        display: none;
    }
    &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 -1rem;
        padding: 0.625rem 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        @include media-breakpoint-up(md) {
            padding: 1rem;
            box-shadow: none;
        }
        h5 {
            margin-bottom: 0;
        }
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 120px);
        @include media-breakpoint-up(md) {
            height: calc(100vh - 130px);
        }
    }
    &-items {
        flex: 2;
        overflow-y: auto;
        margin: 0 -1rem;
        padding: 0 1rem;
        padding-top: 1rem;
        @include media-breakpoint-down(sm) {
            margin-bottom: 157px;
        }
    }
    &-additional {
        position: sticky;
        bottom: 0;
        z-index: 10;
        background: $thrive-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 -1rem;
        padding: 1rem;
        border-top: 1px solid $thrive-light-gray;
    }
    &-subtotal {
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        .price-container {
            .price {
                font-size: 1.25rem;
            }
        }
    }
    .product-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid $thrive-light-gray;
        margin-bottom: 1rem;
        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
        &-details {
            flex: 1;
            margin-left: 1rem;
            .product-item-delivery-text {
                color: #f6a81c;
            }
        }
        &-name {
            margin-right: 1rem;
            margin-bottom: 1rem;
            &-link {
                font-family: $headings-font-family;
                line-height: 1;
                color: $thrive-green;
                .page-header & {
                    &,
                    &:hover,
                    &:active,
                    &:visited,
                    &:focus {
                        color: $thrive-green;
                }
                }
            }
            &-price {
                display: flex;
                justify-content: space-between;
            }
        }
        &-options {
            opacity: 0.75;
            dl,
            dd {
                margin-bottom: 0;
            }

        }
        &-actions {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }
        &-qty {
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            input {
                width: 3rem;
            }
        }
        &-price {
            white-space: no-wrap;
            text-align: right;
            .price-including-tax,
            .price-excluding-tax {
                font-size: 1rem;
                .price {
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }
    }
}

.cart-open {
    .minicart:not(.ds-minicart) {
        transform: translateX(0);
        margin-left: 0;
    }
    .minicart-overlay:not(.ds-minicart-overlay) {
        display: block;
    }
}

.ds-cart-open {
    .ds-minicart {
        transform: translateX(0);
        margin-left: 0;
        .minicart-items {
            padding-bottom: 1rem;
            margin-bottom: 10rem;
        }
    }
    .ds-minicart-overlay {
        display: block;
    }
}

.minicart-loading {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
}

html,
body {
    &.cart-open {
        position: relative;
        height: 100%;
        overflow: hidden;
    }
}

.cart-close {
    padding: 0.375rem 0 0.375rem 0.75rem;
    opacity: 0.5;
    cursor: pointer;
}
