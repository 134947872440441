html {
    font-size: 16px;
}

body {
    background-color: $thrive-white;
    line-height: initial;
    &.nav-open {
        @include media-breakpoint-down(md) {
            position: relative;
            overflow: hidden;
        }
    }
}

// Add container styles to elements that we can't easily change classes on
body:not(.page-layout-fullwidth) {
    .page-wrapper {
        & > .breadcrumbs,
        & > .page-title-wrapper {
            @include make-container();
            @include make-container-max-widths();
        }
    }
}

.breadcrumbs {
    margin: {
        top: 1rem;
        bottom: 1rem;
    }
    .item {
        font-size: 1rem;
        &:not(:last-child):after {
            vertical-align: middle;
        }
    }
}

#social-login-popup {
    max-width: 400px;
    .social-login.authentication {
        .social-login-title {
            background: $thrive-white;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $thrive-light-gray;
            .login-title {
                background-image: none;
                padding-left: 0;
                color: $thrive-black;
                text-transform: capitalize;
            }
        }
        .social-login-customer-authentication {
            .block-title {
                display: none;
            }
            .control {
                width: 100%;
                float: unset;
            }
        }
    }
}

.category {
    &-wrapper {
        margin: 1rem 0;
        padding: 1rem 0;
        h2 {
            margin-bottom: 2rem;
        }
        a {
            &,
            &:hover,
            &:active,
            &:focus,
            &:visited {
                color: $thrive-black;
            }
        }
    }
    &-subcategory {
        display: block;
        margin-bottom: 2rem;
        text-align: center;
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            margin-bottom: 1rem;
            @include media-breakpoint-up(md) {
                height: 150px;
            }
        }
        h3 {
            font-size: 1rem;
            font-weight: normal;
            margin-bottom: 0;
        }
    }
}

// Fix .col table columns that Magento adds
tr > .col {
    display: table-cell;
    width: auto;
    max-width: none;
}

.text-line-through {
    text-decoration: line-through;
}

a.btn-dark {
    &:visited,
    &:hover,
    &:active,
    &:focus {
        color: $thrive-white;
    }
}

.thrive-radio {
    display: block;
    position: relative;
    padding-left: 4rem;
    cursor: pointer;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .thrive-radio-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        width: 36px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $thrive-gray;
        .disabled & {
            background-color: $thrive-gray;
            opacity: 0.5;
        }
    }
    .disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.thrive-radio:hover input ~ .thrive-radio-checkmark {
    background-color: $thrive-gray;
}

.thrive-radio input:checked ~ .thrive-radio-checkmark {
    background-color: #fff;
}

.thrive-radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.thrive-radio input:checked ~ .thrive-radio-checkmark:after {
    display: block;
}

.thrive-radio .thrive-radio-checkmark:after {
    top: 8px;
    left: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $thrive-green;
}

.freeze-dried {
    color: #bbb;
    font-size: 10px;
    font-family: proxima-nova, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 10px;
}

.sharePage {
    font-size: 16px;
    color: black;
}

.block-dashboard-info {
    position: relative;
    .dashboard-myoffice-link {
        position: absolute;
        bottom: 1.25rem;
        @include media-breakpoint-up(sm) {
            bottom: auto;
            top: 1.25rem;
        }
        right: 1.25rem;
    }
}

// Hack to work around Magento's ability to de-select swatch options
.swatch-option.selected {
    pointer-events: none;
}
