//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    .shipping-information {
        &-title {
            font-size: 1.25rem;
            font-weight: 300;
            &-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding-bottom: 1rem;
                @include lib-css(border-bottom, $checkout-step-title__border);
                margin-bottom: 1rem;
            }
        }
    }

    .ship-to {
        margin-bottom: 2rem;
    }
}
