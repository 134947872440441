//
//  Product Lists
//  _____________________________________________

.products-grid {
    padding: $indent__l 0;
    margin-left: -1rem;
    margin-right: -1rem;
    overflow-x: hidden;
    .products {
        padding: 0 1rem;
    }
    .product {
        &-items {
            @extend .abs-reset-list;
            align-items: stretch;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-right: -2rem;
        }

        &-item {
            @extend .abs-add-box-sizing;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            width: calc(48% - 1rem);
            margin: calc(5% - 1rem) calc(5% - 1rem);
            @include min-screen($screen__m) {
                width: calc(31% + 1px);
            }
            @include min-screen($screen__l) {
                width: calc(22% + 10px);
            }
            @include min-screen($screen__xl) {
                width: calc(18% + 7px);
            }
            &-sticker {
                position: relative;
            }
            .page-products & {
                background: #fff;
                border: 2px $thrive-light-gray solid; //TODO color
                .product-item-inner {
                    visibility: visible;
                }
                @include max-screen($screen__m) {
                    margin: calc(5% - 1rem) calc(5% - 1rem) !important;
                }
                @include min-screen($screen__m) {
                    margin: calc(3% - 1rem) calc(3% - 1rem);
                }
                @include min-screen($screen__l) {
                    margin: calc(2.5% - 1rem) calc(2.5% - 1rem);
                }
                @include min-screen($screen__xl) {
                    margin: calc(2% - 1rem) calc(2% - 1rem);
                }
            }

            &-name {
                @extend .abs-product-link;
                display: block;
                font-weight: 500;
                min-height: 20px;
                margin: $indent__xs 0;
                word-wrap: break-word;
                hyphens: auto;
                a:hover {
                    text-decoration: none;
                }

            }

            &-inner {
                margin-top: 0.5rem;
                @media (pointer: fine) {
                    visibility: hidden;
                }
            }

            &-details {
                flex: 1;
                min-width: 100px;
                position: relative;
            }

            &-photo {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                margin-bottom: 10px;
                > img {
                    object-fit: contain;
                    height: 132px;
                    @include min-screen($screen__m) {
                        height: 190px;
                    }
                    @include min-screen($screen__xl) {
                        height: 176px;
                    }
                }
            }

            .product-reviews-summary {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.5rem 0;
                .rating-summary {
                    margin: 0;
                    left: unset;
                }

                .reviews-actions {
                    font-size: $font-size__s;
                    margin-top: 0;
                    text-transform: lowercase;
                }
            }

            .price-box {
                margin: 0.5rem;
                .price {
                    font-size: 14px;
                    font-weight: $font-weight__bold;
                    white-space: nowrap;
                }

                .price-label {
                    font-size: $font-size__s;

                    &:after {
                        content: ':';
                    }
                }
            }

            .special-price,
            .minimal-price {
                .price {
                    font-size: 14px;
                    font-weight: $font-weight__bold;
                }

                .price-wrapper {
                    display: inline-block;
                }

                .price-including-tax + .price-excluding-tax {
                    display: block;
                }
            }

            .special-price {
                display: block;
            }

            .old-price {
                .price {
                    font-weight: $font-weight__regular;
                }
            }

            .minimal-price {
                .price-container {
                    display: block;
                }
            }

            .minimal-price-link {
                margin-top: 5px;

                .price-label {
                    color: $link__color;
                    font-size: 14px;
                }

                .price {
                    font-weight: $font-weight__regular;
                }
            }

            .minimal-price-link,
            .price-excluding-tax,
            .price-including-tax {
                display: block;
                white-space: nowrap;
            }

            .price-from,
            .price-to {
                margin: 0;
            }

            .action.tocompare {
                @include lib-icon-font-symbol($icon-compare-empty);
            }

            .tocart {
                white-space: nowrap;
            }
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

//
//  Tablet and larger
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        &.#{$layout-class-2columns__left},
        &.#{$layout-class-2columns__right} {
            .products-grid .product-item {
                width: calc(33.3333% - 1rem);
            }
        }
        &.#{$layout-class-3columns} {
            .products-grid .product-item {
                width: calc(50% - 1rem);
            }
        }
    }
}
