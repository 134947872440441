//
//  Responsive variables
//  _____________________________________________

$use-flex    : true !default; // Use flexbox [true|false]
$responsive  : true !default; // Theme is responsive [true|false]

// These are modified to be more compatible with Bootstrap 4 standards
$screen__xs  : 480px !default;
$screen__s   : 576px !default;
$screen__m   : 768px !default;
$screen__l   : 992px !default;
$screen__xl  : 1200px !default;
