// Use Bootstrap 'form-control'-like style for Magento default text inputs
input[type="text"]:not(.form-control),
input[type="password"]:not(.form-control),
input[type="url"]:not(.form-control),
input[type="tel"]:not(.form-control),
input[type="search"]:not(.form-control),
input[type="number"]:not(.form-control),
input[type="datetime"]:not(.form-control),
input[type="email"]:not(.form-control),
.control > textarea:not(.form-control) {
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus($ignore-warning: true);

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
}

.input-text {
    display: block;
    width: $input-text__width;
}

.control > textarea:not(.form-control) {
    display: block;
    width: 100%;
    height: 3rem;
}

.mage-error {
    color: $danger;
}
