$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    flex: 1;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter {
    .sort-desc {
        &:before {
            content: $icon-arrow-down;
        }
    }
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .limiter {

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}
