:root {
    --consultant-banner-color: unset;
    --consultant-banner-display: flex;
}

.page-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: $thrive-white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
    .checkout-index-index & {
        box-shadow: none;
        position: static;
        z-index: 0;
        border-bottom: none;
        .header.content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 1rem;
        }
        .logo {
            display: block;
            float: unset;
            position: static;
            margin: 0 auto;
            img {
                @include min-screen($screen__l) {
                    max-width: 250px;
                }
            }
        }
    }
}

.nav-toggle.btn-link {
    display: block;
    margin-right: 0.5rem;
    font-size: 20px;
    color: $thrive-black;
    position: static;
    border: none;
    border-radius: 0;
    padding: {
        left: 1rem;
        right: 1rem;
    }
    &:hover,
    &:focus,
    &:active {
        color: $thrive-black;
    }
    &:before {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.navbar-brand {
    padding: {
        top: 0;
        bottom: 0;
    }
    .logo {
        float: unset;
        position: static;
        margin: 0;
        display: block;
        max-width: unset;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px 0;
        @include media-breakpoint-up(lg) {
            height: auto;
            display: block;
            padding: 0;
        }
        img {
            width: auto;
            height: 30px;
            @include media-breakpoint-up(lg) {
                height: 50px;
            }
        }
    }
}

.primary-nav {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: {
        left: 0;
        right: 0;
    }
    @include media-breakpoint-up(lg) {
        padding: {
            left: 2rem;
            right: 2rem;
        }
    }

    &.navbar {
        padding: {
            top: 0;
            bottom: 0;
        }
    }



    &-left {
        display: flex;
        @include media-breakpoint-up(lg) {
            width: 25%;
        }
        @include media-breakpoint-up(xl) {
            width: 33%;
        }
    }

    &-middle {
        @include media-breakpoint-down(md) {
            position: fixed;
            transform: translateX(-120%);
            top: 0;
            left: 0;
            right: 20%;
            bottom: 0;
            z-index: 1021;
            max-height: 100%;
            flex-direction: column;
            background: $thrive-white;
            backdrop-filter: blur(10px) saturate(180%);
            overflow: hidden;
            &.nav-open {
                transform: translateX(0);
                margin-left: 0;
            }
            &.nav-transition {
                transition: transform 0.2s ease;
            }
            .sub-nav-transition & {
                overflow-y: scroll;
            }
        }
        @include media-breakpoint-only(md) {
            right: 60%;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
        }
        @include media-breakpoint-up(xl) {
            width: 33%;
        }
        .dropdown-toggle {
            &:after {
                display: inline-block;
                margin-left: unset;
                vertical-align: unset;
                content: "";
                border-top: 0;
                border-right: 0;
                border-bottom: 0;
                border-left: 0;
            }
            @include media-breakpoint-down(md) {
                transform: translateX(0);
                transition: transform 0.2s ease, display 0.2s ease;
                .sub-nav-transition & {
                    transform: translateX(-120%);
                    position: absolute;
                }
            }
        }
        .navbar-nav {
            @include media-breakpoint-down(md) {
                display: block;
            }
            > li {
                border-bottom: 1px solid $thrive-light-gray;
                margin-bottom: 0;
                .sub-nav-transition & {
                    border-bottom: none;
                }
                @include media-breakpoint-up(lg) {
                    padding: 0;
                    border-bottom: none;
                    &:hover,
                    &.show {
                        box-shadow: inset 0 -2px 0 0 $thrive-green;
                    }
                }
                > a {
                    color: $thrive-black;
                    display: flex;
                    justify-content: space-between;
                    padding: 1rem;
                    @include media-breakpoint-up(lg) {
                        display: block;
                        padding: 35px 20px;
                    }
                    &:hover {
                        text-decoration: unset;
                    }
                    span {
                        flex: 1;
                    }
                    .fa-chevron-right {
                        color: $thrive-gray;
                        @include media-breakpoint-up(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &-right {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(lg) {
            width: 30%;
        }
    }
}

.secondary-nav {
    background: var(--consultant-banner-color);
    padding: {
        left: 0;
        right: 0;
        top: 0.25rem;
        bottom: 0.25rem;
    }
    @include media-breakpoint-up(lg) {
        padding: {
            left: 2rem;
            right: 2rem;
            top: 0.25rem;
            bottom: 0.25rem;
        }
    }
}

.secondary-nav-content {
    display: var(--consultant-banner-display);
    justify-content: flex-end;
    align-items: center;
}

.banner-content{
    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-items: center;
    }

    li:nth-child(1) {
        grid-column-start: 2;
        @include media-breakpoint-up(lg) {
            padding-left: 10rem;
        }
    }

    li:nth-child(2) {
        margin-left: auto;
    }
}

.search-text {
    &.form-control {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        border-radius: 2rem;
        font-size: 0.875rem;
    }
}

.nav-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1019;
    text-align: right;
    padding: 20px;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1px) saturate(150%);
    &.nav-open {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    .fa-times {
        font-size: 40px;
        color: $thrive-white;
    }
}

.search-holder {
    margin-bottom: 0;
    margin-right: 0;
    @include media-breakpoint-up(lg) {
        display: block;
        margin-right: 1rem;
    }
    .search-query {
        width: 100%;
        position: relative;
        label {
            position: absolute;
            left: 17px;
            top: 8px;
            margin-bottom: 0;
            pointer-events: none;
        }
    }
}

.minicart-wrapper {
    .primary-nav-right & {
        margin-top: 0;
        margin-left: 0;
        .nav-open & {
            display: block;
            float: unset;
        }
    }
}

.nav-category {
    padding: 2rem 1rem;
    border-bottom: 1px solid $thrive-light-gray;
    &:last-of-type {
        border-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        border-bottom: none;
        max-width: 200px;
        white-space: nowrap;
        &:first-of-type {
            padding-left: 0;
        }
        &:last-of-type {
            padding-right: 0;
        }
    }
    a {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        color: $thrive-dark-gray;
        font-weight: bold;
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            display: block;
        }
    }
    h3 {
        margin: 0;
        margin-left: 10px;
        font-size: 14px;
        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-bottom: 10px;
            font-size: 20px;
        }
    }
    img {
        max-height: auto;
        max-width: 50px;
        @include media-breakpoint-up(lg) {
            max-height: 150px;
            max-width: unset;
            width: 100%;
        }
    }
    &-header {
        background: $thrive-dark-gray;
        color: $thrive-white;
        padding: 1rem;
        font-size: 20px;
        border-top: 1px solid $thrive-light-gray;
        border-bottom: 1px solid $thrive-light-gray;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.nav-sub-categories {
    list-style: none;
    padding: 0;
    a {
        font-weight: normal;
    }
    li {
        margin: 1.5rem 0;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include media-breakpoint-up(lg) {
            margin: 0.325rem 0;
        }
    }
}

.nav-category-container {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: center;
    }
}

.yamm {
    // reset positions
    .collapse,
    .dropup,
    .dropdown {
        position: static;
    }

    // propagate menu position under container for fullwidth navbars
    .container {
        position: relative;
    }

    // by default aligns menu to left
    .dropdown-menu {
        &:not(.dropdown-menu-account) {
            margin: 0;
            left: auto;
            border: {
                radius: 0;
                left: none;
                right: none;
                color: $thrive-light-gray;
            }
            @include media-breakpoint-down(md) {
                position: absolute;
                top: 0;
                bottom: 0;
                display: block;
                background: $thrive-white;
                box-shadow: none;
                border: {
                    top: none;
                    bottom: none;
                }
                padding: {
                    top: 0;
                    bottom: 0;
                }
                transform: translateX(120%);
                transition: transform 0.2s ease, display 0.2s ease;
                &.show {
                    position: static;
                    transform: translateX(0);
                }
            }
        }
    }

    // Fullwidth menu
    .dropdown,
    .dropup {
        &.yamm-fw .dropdown-menu {
            left: 0;
            right: 0;
        }
    }
}


.main-menu-btn {
    display: none;
    padding: 1rem;
    @include media-breakpoint-down(md) {
        position: absolute;
        display: block;
        width: 100%;
        transform: translateX(120%);
        transition: transform 0.2s ease, display 0.2s ease;
        .sub-nav-transition & {
            position: static;
            transform: translateX(0);
        }
    }
    .fa-chevron-left {
        color: $thrive-gray;
        margin-right: 0.5rem;
    }
}

.mgs-instant-autocomplete-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 1000;
    padding: 1rem;
    font-size: 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $thrive-light-gray;
    border-radius: 0.25rem;
    @include media-breakpoint-down(md) {
        border-top: {
            left-radius: 0;
            right-radius: 0;
        }
        margin: {
            top: 0.5rem;
            left: -0.5rem;
            right: -0.5rem;
        }
    }
    .pages-list,
    .posts-list,
    .categories-list,
    .products-list {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        .title {
            margin-bottom: 0.5rem;
            > span {
                font-weight: bold;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 0;
        }
    }
    .product-item,
    .category-item {
        margin-bottom: 0.5rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        &-info {
            display: flex;
        }
        &-photo {
            img {
                width: 50px;
                height: auto;
            }
        }
    }
}

.consultant-header {
    padding: 0rem 1rem;
    &-profile {
        &-label {
            // font-size: 0.75rem;
            &,
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: $thrive-dark-gray;
            }
        }
        &-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &-img {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background: $thrive-light-gray;
            margin-right: 0.5rem;
            overflow: hidden;
        }
    }
    &-manage {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius:  .25rem;
    }

}

.region-header{
    padding: 0rem 1rem;
    align-self: center;
    @include media-breakpoint-down(sm) {
        padding-top: 8px;
    }
}

// Login as Customer banner
.lac-notification .top-container {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
}
.lac-notification-icon,
.lac-notification-links {
    padding: 0.5rem;
}
.lac-notification-text {
    flex: 1;
}

// Promo banner
.mppromobar-style {
    display: flex;
    justify-content: flex-end;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
    }
    .mppromobar-text {
        justify-content: center;
        flex-basis: 100%;
    }
    .mppromobar-close-btn {
        cursor: pointer;
        position: absolute;
        top: 10%;
    }
}

// Welcome Modal
.modals-wrapper {
    .modal-content.consultant-modal {
        max-width: 380px;

        .modal-header {
            border: none;
            span.modal-subtitle {
                display: block;
                font-size: .8em;
                color: $thrive-salmon;
            }
        }

        .modal-body {
            padding: 0 0 1rem;
            #consultant-modal {
                .card-header {
                    padding-bottom: 2rem;
                    margin-bottom: 4rem;
                    border-radius: unset;
                    .consultant-header-profile-img {
                        margin: auto;
                        width: 6rem;
                        height: 6rem;
                        margin-bottom: -80px;
                        border: 2px solid $thrive-white;
                    }
                }

                .consultant-name {
                    text-align: center;

                    a {
                        font-size: 1.2em;
                        color: $thrive-black;
                    }
                }

                .web-url {
                    padding: 0 1rem;
                    text-align: center;
                    p {
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                        font-size: .7rem;
                        color: #808080; // should be added to variables?
                    }
                }

                .welcome-message {
                    padding: 0 1rem;
                    p {
                        margin-bottom: 0;
                        font-size: .8em;
                        line-height: 1.5;
                        color: #808080; // should be added to variables?
                    }
                    div { // styling for static block
                        margin-bottom: 0;
                        font-size: .8em;
                        line-height: 1.5;
                        color: #808080; // should be added to variables?
                    }
                }
            }
        }

        .modal-footer {
            justify-content: space-between;
            border: none;
            button.modal-change {
                span {
                    span {
                        color: #29797f;
                    }
                }
            }
        }
    }
}

// Consultant Find Modal Home Page Only
.modals-wrapper {
    .modal-content.consultant-find {
        max-width: 500px;
        .modal-header {
            button.close {
                display: none;
            }
        }
        .consultant-find-content {
            .change-consultant-search-result {
                margin-top: 1rem;
                border: none;
                max-height: 250px;
                overflow-y: auto;
                .change-consultant-selection-box {
                    cursor: pointer;
                    padding: 0.675rem;
                    width: 100%;
                    margin-bottom: 0;
                    border: 1px solid #ededed;
                    border-radius: 0.25rem;
                    img {
                        float: left;
                        object-fit: cover;
                        width: 56px;
                        height: 56px;
                        margin: 2px 10px 2px 0;
                        border-radius: 50%;
                    }
                    .consultant-name-url {
                        margin: 10px 0;
                    }
                }
                .change-consultant-selection-box:hover {
                    background-color: rgba(127, 127, 127, 0.1);
                }
                input:checked + .change-consultant-selection-box {
                    // border-color: var(--consultant-brand-color);
                    border-color: $thrive-green;
                    background-color: $thrive-light-gray;
                    // box-shadow: 0 0 0 2px var(--consultant-brand-color);
                    box-shadow: 0 0 0 2px $thrive-green;
                }
                .recently-visited-repeat {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
