$sidebar-width: 280px;

.columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    grid-gap: 1rem;
}

.column.main {
    grid-column: 1 / 4;
    grid-row: 1;
    .table-wrapper.stripe-payments {
        td.saved-payment-method-name {
            img {
                margin-top: -20px;
            }
            label {
                margin-top: 10px;
            }
        }
    }
}

.sidebar-main {
    grid-column: 1 / 4;
    grid-row: 2;
    .#{$layout-class-1column} & {
        display: none;
    }
}

.sidebar-additional {
    grid-column: 1 / 4;
    grid-row: 3;
    .#{$layout-class-1column} & {
        display: none;
    }
}

@include min-screen($screen__l) {
    .columns {
        grid-template-columns: $sidebar-width auto $sidebar-width;
    }

    .column.main {
        .#{$layout-class-1column} & {
            grid-column: 1 / 4;
        }
        .#{$layout-class-3columns} & {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
        }
        .#{$layout-class-2columns__left} & {
            grid-column: 2 / 4;
            grid-row: 1 / 3;
        }
        .#{$layout-class-2columns__right} & {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
        }
    }

    .sidebar-main {
        grid-row: 1;
        .#{$layout-class-3columns} & {
            grid-column: 1;
        }
        .#{$layout-class-2columns__left} & {
            grid-column: 1;
        }
        .#{$layout-class-2columns__right} & {
            grid-column: 3;
        }
    }

    .sidebar-additional {
        grid-row: 2;
        .#{$layout-class-3columns} & {
            grid-column: 3;
        }
        .#{$layout-class-2columns__left} & {
            grid-column: 1;
        }
        .#{$layout-class-2columns__right} & {
            grid-column: 3;
        }
    }
}
