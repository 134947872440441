//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px !default;
$form-calendar-icon__color: $primary__color !default;

select.select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center / 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.field {
    .field-tooltip {
        position: relative;
        display: inline;
        .label {
            display: none;
        }
        .field {
            &-tooltip-action {
                &:before {
                    content: '\f059';
                    font-family: "Font Awesome 5 Free";
                    color: $thrive-dark-gray;
                    font-size: 1rem;
                }
            }
        }
    }
    .field-note {
        font-size: 80%;
        font-weight: 400;
        display: block;
        margin-top: .25rem;
        color: #6c757d;
    }
}



