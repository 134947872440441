#social-login-popup {
    .mfp-container & {
        max-width: 600px;
    }

    .btn-social,
    .btn-social:hover {
        color: #fff;
    }

    .actions-toolbar {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .primary, .secondary {
            float: none;
            margin-top: 0;
            a, button {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .primary {
            display: flex;
            align-items: center;
            & > .secondary {
                margin-left: 1rem;
            }
        }
    }

    .mfp-close {
        color: #444 !important;
    }
    .social-login-title {
        background-color: transparent !important;
        h2 {
            color: inherit;
        }
        & .create-account-title,
        & .forgot-pass-title {
            background-image: none;
            padding-left: 0.5rem;
        }
    }
}

.account-social-login {
    .btn-social,
    .btn-social:hover {
        color: #fff;
    }
}
