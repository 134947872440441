.join-team {
    .green {
        color: $thrive-green;
    }

    .list-with-dots {
        list-style-type: disc;
    }

    .firstrow {
        overflow: hidden;

        h2 {
            color: $thrive-green;
        }
    }
    .thirdrow {
        overflow: hidden;
    }

    .fiftrow {
        background-color: #76BC2140;
    }

    .thirdrow-points p {
        margin-bottom: 5px;
    }

    .social-img {
        padding-bottom: 90%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 30px;
    }

    .fd-container {
        background: $thrive-green;
        color: #fff;
    }

    .start-up {
        overflow: hidden;
        .starter-kits {
            background-color: #76BC2140;
        }
        .bundle-accordion {
            .content {
                display: none;
            }
            &.active {
                .title {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
                .content {
                    display: block;
                }
            }
        }
        .title {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 1em 0;
            margin-top: 1em;
            position: relative;
            cursor: pointer;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 20px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid rgba(0, 0, 0, 0.3);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .join-team {
        .firstrow {
            div {
                position: relative;

                &:last-child {
                    p:first-child {
                        font-size: 36px;
                        padding-bottom: 25px;
                    }
                }
                > img {
                    top: 0;
                    width: 100%;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .mb-small-5 {
        margin-bottom: 3rem;
    }
}

@include media-breakpoint-up(md) {
    .join-team {
        .firstrow {
            overflow: hidden;

            .img-circle {
                top: -100px;
            }
            .col-md-7 {
                order: 2;
            }
            div:last-child {
                p:first-child {
                    font-size: 40px;
                }
            }
        }
        .thirdrow {
            overflow: hidden;
        }
        .thirdrow-quote {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            p {
                max-width: 490px;
            }
        }
    }
}

.quality-page,
.survival-page {
    .actions {
        p {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #76BC21;
        }
        .btn {
            min-width: 140px;
        }
    }

    .info {
        padding: 0 20px;
        margin-bottom: 50px;
        max-width: 290px;

        h3 {
            font-size: 20px;
            line-height: 1.2;
            font-weight: bold;
            margin: 0;
        }
        .title {
            padding: 0 20px;
            margin: auto;

            p {
                font-size: 20px;
                line-height: 1.2;
            }
        }
        p {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.quality-header {
    height: 490px;
    background-image: url('/media/wysiwyg/quality/Mobile-Quality_LP_header.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .info-block {
        text-align: center;
        width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 22px;
            line-height: 26px;
            font-weight: bold;
        }
        p {
            font-size: 18px;
            line-height: 22px;
        }
        .btn {
            min-width: 140px;
        }
    }

    .survival-page & {
        height: 650px;
        background-image: url('/media/wysiwyg/survival/HeaderMobile_Survival_LP_Image.png');
    }
}
.quality-subheader {
    background-color: #D2E9B6;
    padding: 70px;
    display: flex;
    justify-content: center;

    p {
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        text-align: center;
        max-width: 680px;
    }
}
.quality-why {
    padding: 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .why-images {
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            line-height: 20px;
            width: 260px;
            margin-bottom: 20px;

            img {
                width: 100%;
                margin-bottom: 1em;
            }
            p {
                padding: 0 20px;
            }
        }
    }
    &.no-img {
        div.info {
            margin-bottom: 30px;

            h3 {
                margin-bottom: 15px;
            }
        }
        a {
            color: #000;
            text-decoration: underline;
        }
        .why-images {
            .item {
                strong {
                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }
    }
}
.quality-adventage {
    background-color: rgba(232, 244, 217, 0.36);
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.info {
        margin-bottom: 30px;
    }
    .items {
        img {
            width: 100%;
            max-width: 680px;
            object-fit: cover;
            aspect-ratio: 1;
            margin-bottom: 30px;
        }
        ul {
            list-style: none;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 30px;
            padding: 0;
        }
    }
}
.quality-meals {
    padding: 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 21px;

    img {
        width: 100%;
        margin-bottom: 10px;
    }

    .meal-items {
        display: flex;
        flex-direction: column;

        .item {
            margin-bottom: 20px;
            max-width: 320px;

            p {
                padding: 0 20px;
            }
        }

    }
    .meal-packs {
        display: flex;
        flex-direction: column;
        max-width: 320px;
        margin-bottom: 30px;

        img {
            margin-bottom: 0;
        }
    }
    &.with-survival {
        div.info {
            margin-bottom: 20px;
            h3 {
                margin-bottom: 15px;
            }
        }
        .meal-items {
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            strong {
                margin-bottom: 10px;
                display: block;
            }
            p {
                text-align: left;
                font-size: 16px;
                line-height: 21px;
            }
            ul {
                text-align: left;
                font-weight: bold;
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 20px;
                padding-left: 15px;
            }
        }
    }
}
.quality-enjoy {
    background-color: #F7FBF1;
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 21px;

    img {
        width: 100%;
        margin-bottom: 20px;
    }
    .item {
        margin-bottom: 40px;
    }
    .items-small {
        width: 245px;
        display: flex;
        flex-direction: column;
    }
    .items-large {
        font-size: 16px;
        line-height: 23px;

        img {
            margin-bottom: 10px;
        }
    }
}
.quality-reviews {
    padding: 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 21px;

    &.green {
        background-color: #FBFFF5;
    }

    .reviews {
        width: 100%;
        background-color: #E8F4D9;
        position: relative;

        .item {
            .inner {
                display: flex;
                flex-direction: column;
                width: 255px;
                padding: 50px 0;
                margin: 0 auto;
            }
            .img {
                margin-bottom: 60px;
            }
            img {
                width: 100%;
            }
            .desc {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            p {
                margin-bottom: 2em;
            }
            strong {
                font-size: 16px;
            }
            .stars {
                width: 225px;
                height: 43px;
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                .rating {
                    height: 43px;
                    background-image: url('/media/wysiwyg/quality/star.png');
                    background-position: 0 0;
                    background-repeat: repeat-x;
                }
            }
        }
    }
    .slick-dots {
        top: 315px;

        li {
            overflow: hidden;
            width: 5px;
            height: 5px;
            background-color: #AFAFAF;
            margin: 0 2px;
            border-radius: 100%;

            button {
                opacity: 0;
            }
            &.slick-active {
                background-color: #000;
            }
        }
    }
}
.quality-faq {
        background-color: #ededed;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 22px;

    div.info {
        margin-bottom: 30px;
        text-align: center;
    }
    .faqs {
        padding: 0 10px;
        width: 100%;
        max-width: 1020px;

        &:after {
            content: "";
            display: block;
            height: 1px;
            background-color: #AFAFAF;
        }
    }
    details {
        &[open] {
            summary {
                &:after {
                    transform: rotate(0);
                }
            }
        }
        summary {
            font-weight: bold;
            border-top: 1px solid #AFAFAF;
            padding: 10px 40px 10px 10px;
            position: relative;
            display: block;

            &::marker {
                display: none;
            }
            &:before {
                content: "Q: ";
            }
            &:after {
                content: "";
                position: absolute;
                top: 15px;
                right: 5px;
                width: 21px;
                height: 11px;
                background-image: url('/media/wysiwyg/quality/arrow.png');
                background-size: 100%;
                transform: rotate(180deg);
            }
        }
        main {
            font-style: italic;
            padding: 0 30px;
            min-height: 120px;

            &:before {
                content: "A: ";
            }
            a {
                color: #000;
            }
        }
    }
}
.quality-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 22px;

    img {
        height: 307px;
        width: 100%;
        max-width: 878px;
        object-fit: cover;
    }
    .actions {
        padding: 30px 40px 40px;
        text-align: center;

        p {
            font-weight: normal;
            color: #000;
            max-width: 560px;
        }
    }
}

.survival-prepare {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    div.info {
        margin-bottom: 30px;
    }
    > img {
        max-width: 100%;
        margin-bottom: 30px;
    }
}

.survival-2col {
    background-color: #ededed;
    padding: 50px 0;
    font-size: 16px;
    line-height: 21px;

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        >img {
            max-width: 100%;
            min-height: 258px;
            object-fit: cover;

            + .first {
                margin-top: 40px;
            }
        }
    }
    .first {
        padding: 0 80px 50px;

        h3 {
            font-size: 20px;
            line-height: 1.2;
            font-weight: bold;
        }
        .desc {
            margin-bottom: 20px;

            ul {
                padding-left: 15px;
            }
        }
    }

    &.green {
        background-color: #E8F4D9;
    }
    &.white {
        background-color: #fff;
    }
    &.dark {
        background-color: #D2CFCF;
    }
}

.survival-why {
    padding: 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.info {
        margin-bottom: 20px;

        h3 {
            margin-bottom: 15px;
        }
    }

    .why-images {
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            line-height: 21px;
            width: 260px;
            margin-bottom: 20px;

            img {
                width: 100%;
                margin-bottom: 1em;
            }
            strong {
                font-size: 18px;
                margin-bottom: 10px;
            }
            p {
                padding: 0 20px;
            }
        }
    }
}

.survival-table {
    padding: 0 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.info {
        text-align: center;
        margin-bottom: 30px;
    }
    .actions {
        text-align: center;
        margin-top: 30px;
    }
    table {
        border-collapse: collapse;
        border-style: hidden;
        border-radius: 3px;
        box-shadow: 0 0 0 1px #B9B9B9;
        width: 100%;
        max-width: 840px;

        th,
        td {
            padding: 10px;
            border: 1px solid #B9B9B9;
            vertical-align: top;
        }
        th {
            background-color: rgba(0, 0, 0, 0.06);
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
        }
        td {
            font-size: 13px;
            line-height: 16px;
        }
        ul {
            padding-left: 10px;
            margin: 0;
        }
    }
}

.survival-promise {
    padding: 50px 20px;
    background-color: #ededed;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.info {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 20px;
        }
    }
    .items {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.survival-features {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        width: 230px;
    }
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 40px;

        img {
            width: 100%;
        }
        strong {
            text-transform: uppercase;
            font-size: 30px;
            line-height: 1.1;
            font-family: "proxima-soft", "Varela Round", sans-serif;
            font-weight: normal;
        }
        .percent {
            font-size: 100px;
            line-height: 1;
            color: #F18A00;
            font-weight: bold;
            font-family: "proxima-soft", "Varela Round", sans-serif;
        }
        p {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
        }
    }
    .actions {
        text-align: center;
        margin-bottom: 30px;
    }
    .footnote {
        text-align: center;

        small {
            font-size: 11px;
            line-height: 14px;
            display: block;
        }
    }
}

.survival-guarantee {
    background-color: #E8F4D9;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.info {
        text-align: center;
        margin-bottom: 40px;

        h3 {
            margin-bottom: 15px;
        }
    }
    .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        margin-bottom: 40px;
    }
    .item {
        width: 253px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #76BC21;
        border-radius: 15px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -15px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 20px 15px 0 15px;
            border-color: #76BC21 transparent transparent transparent;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }

        img {
            width: 100%;
        }
        strong {
            color: #76BC21;
            padding: 10px 0;
            border-bottom: 1px solid #76BC21;
            display: block;
            font-family: "proxima-soft", "Varela Round", sans-serif;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            line-height: 21px;
        }
    }
}

.survival-try {
    background-color: #ededed;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    div.info {
        text-align: center;
        margin-bottom: 40px;

        h3 {
            margin-bottom: 15px;
        }
    }
    .items {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .item {
        background-color: #fff;
        padding: 20px 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        font-size: 16px;
        line-height: 21px;

        img {
            max-width: 100%;
        }
        .inner {
            width: 160px;

            strong {
                font-size: 18px;
                display: block;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 30px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .quality-adventage {
        .info {
            > p {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .quality-page,
    .survival-page {
        .info {
            max-width: 570px;
            margin-bottom: 80px;

            h3 {
                font-size: 24px;
                line-height: 33px;
            }
            .title p {
                font-size: 24px;
                line-height: 33px;
            }
        }
    }
    .quality-header {
        height: 560px;
        background-image: url('/media/wysiwyg/quality/QualityLandingPage_Header2.jpg');

        .info-block {
            width: 380px;

            h1 {
                font-size: 30px;
                line-height: 1.2;
            }
            p {
                font-size: 22px;
                line-height: 28px;
            }
        }

        .survival-page & {
            height: 600px;
            background-image: url('/media/wysiwyg/survival/Header_Desktop_Survival_LP_Image.png');
        }
    }
    .quality-subheader {
        padding: 60px;

        p {
            font-size: 20px;
            line-height: 26px;
        }
    }
    .quality-why {
        padding: 80px 20px;

        .why-images {
            display: flex;
            margin-bottom: 50px;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
            max-width: 1200px;

            .item {
                width: 30%;
                max-width: 320px;
                margin-bottom: 0;
                font-size: 20px;
                line-height: 23px;

                img {
                    margin-bottom: 20px;
                }
            }
        }
        &.no-img {
            .why-images {
                margin-bottom: 0;

                .item {
                    max-width: 360px;
                }
            }
        }
    }
    .quality-adventage {
        padding: 80px 0;

        .items {
            img {
                aspect-ratio: auto;
            }
            ul {
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 40px;
            }
        }
    }
    .quality-meals {
        padding: 80px 20px;

        div.info {
            margin-bottom: 20px;
        }
        .meal-items {
            justify-content: space-between;
            gap: 20px;
            width: 100%;
            max-width: 1200px;
            flex-direction: row;
            flex-wrap: wrap;

            .item {
                width: 30%;
                max-width: 320px;
            }
        }
        .meal-packs {
            width: 100%;
            max-width: 1200px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .packs {
                width: 100%;
                display: flex;
                gap: 20px;
                justify-content: space-between;

                img {
                    width: 30%;
                    max-width: 320px;
                }
            }
            p {
                max-width: 280px;
            }
        }
        &.with-survival {
            .meal-items {
                .item {
                    align-items: flex-start;

                    strong,
                    .actions {
                        width: 100%;
                    }
                    ul {
                        margin: 0 30px 30px;
                    }
                    .actions {
                        margin-top: auto;
                    }
                }
            }
        }
    }
    .quality-enjoy {
        padding: 80px 20px;
        font-size: 20px;
        line-height: 23px;

        img {
            margin-bottom: 30px;
        }
        div.info {
            margin-bottom: 40px;

            .title {
                padding: 0;
            }
        }
        .items-small {
            width: 100%;
            max-width: 1300px;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;

            .item {
                width: 22%;
                max-width: 280px;
            }
        }
        .items-large {
            display: flex;
            width: 100%;
            max-width: 1300px;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                width: 30%;
                max-width: 364px;
            }
        }
    }
    .quality-reviews {
        padding: 80px 0;

        div.info {
            margin-bottom: 30px;
        }
        .reviews {
            width: 100%;
            max-width: 880px;
            padding: 0 80px;

            .item {
                .inner {
                    width: 100%;
                    flex-direction: row;
                    gap: 40px;
                    align-items: center;
                }
                .img {
                    margin-bottom: 0;
                    width: 340px;
                    flex-shrink: 0;
                }
                .desc {
                    align-items: flex-start;
                    text-align: left;
                }
                .stars {
                    justify-content: flex-start;
                }
                p {
                    margin-bottom: 20px;
                    font-size: 20px;
                    line-height: 24px;
                }
                strong {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
        .slick-dots {
            display: flex;
            flex-direction: column;
            top: 50%;
            transform: translateY(-50%);
            left: 30px;
            gap: 10px;
            width: 12px;

            li {
                width: 12px;
                height: 12px;
                margin: 0;
            }
        }
    }
    .quality-faq {
        padding: 80px 30px;
        font-size: 20px;
        line-height: 26px;

        details {
            summary {
                padding-block: 15px;

                &:after {
                    top: 22px;
                }
            }
        }
    }
    .quality-footer {
        padding: 100px 0 20px;

        .actions {
            p {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }

    .survival-prepare {
        padding: 80px 0;
    }

    .survival-2col {
        .inner {
            width: 100%;
            max-width: 1200px;
            flex-direction: row;
            margin: 0 auto;

            > img {
                width: 47%;
                flex-shrink: 0;

                + .first {
                    margin-top: 0;
                }
            }
        }
        .first {
            padding: 40px 70px;

            h3 {
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 30px;
            }
            .desc {
                margin-bottom: 30px;
            }
        }
        &.revert {
            .first {
                order: 2;
            }
        }
    }

    .survival-why {
        padding: 80px 20px;

        div.info {
            margin-bottom: 50px;
        }
        .why-images {
            display: flex;
            margin-bottom: 50px;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
            max-width: 1200px;

            .item {
                width: 23%;
                max-width: 236px;
                margin-bottom: 0;
                font-size: 20px;
                line-height: 23px;

                img {
                    margin-bottom: 20px;
                }
                p {
                    padding: 0;
                }
            }
        }
    }
    .survival-table {
        padding: 20px 20px 80px;

        div.info {
            margin-bottom: 40px;
        }
        .actions {
            margin-top: 40px;
        }
    }
    .survival-promise {

        div.info {
            margin-bottom: 10px;
        }
        .items {
            flex-direction: row;

            img {
                max-width: 25%;
            }
        }
    }
    .survival-features {
        padding: 80px 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            width: 100%;
            max-width: 1200px;
        }
        .items {
            display: flex;
            justify-content: space-between;
        }
        .item {
            width: 230px;
        }
        .actions {
            margin-bottom: 40px;
        }
    }

    .survival-guarantee {
        padding: 80px 20px;

        div.info {
            margin-bottom: 60px;
        }
        .items {
            flex-direction: row;
            align-items: normal;
            justify-content: center;
            gap: 30px;
            margin-bottom: 30px;
        }
        .item {
            width: 30%;
            max-width: 302px;
            padding: 25px;

            &:after {
                top: 230px;
                left: 100%;
                margin-left: -4px;
                transform: rotate(-90deg);
                border-width: 16px 12px 0 12px;
            }
            strong {
                padding-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
    .survival-try {
        padding: 80px 20px;

        .items {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 1200px;
        }
        .item {
            width: 48%;
            max-width: 544px;

            .inner {
                width: 100%;
                max-width: 330px;
            }
            .btn {
                width: 100%;
            }
        }
    }
}
