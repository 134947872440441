 .swatch-attribute-label {
    font-weight: bold;
    position: relative;
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px;
}

.swatch-attribute-selected-option {
    display: none;
}

.swatch-attribute-options {
    margin: 0.5rem 0 2rem 0;
    display: grid;
    grid-template-columns: 50% calc(50% - 10px);
    gap: 10px;
    flex-wrap: wrap;
    @include max-screen($screen__m) {
        overflow-x: scroll;
        max-width: calc(100vw - 30px);
        padding-bottom: 10px;
        margin-bottom: 1.25rem;
    }
}

.swatch-option {
    cursor: pointer;
    &.image,
    &.color {
        padding: 1px 2px;
        min-width: 30px;
        max-width: 90px;
        height: 20px;
        float: left;
        margin: 0 10px 5px 0;
        text-align: center;
        position: relative;
        border: 1px solid rgb(218, 218, 218);
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.text {
        padding: 0.5rem 0.75rem;
        border: 1px solid $thrive-gray;
        background: lighten($thrive-light-gray, 2%);
        border-radius: 0.25rem;
        text-align: left;
        display: block;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        flex: 1;
        &.quickshop {
            .packageType {
                font-size: 14px;
            }
            .price {
                font-size: 16px;
                padding: 4px 0px;
            }
            .outOfStock {
                font-size: 10px;
                color: #da505a;
            }
            .servingSize {
                font-size: 10px;
            }
        }
        &.selected,
        &:hover:not(.disabled) {
            .delivery & {
                background: rgba(246, 168, 28, 0.2);
                border-color: $thrive-orange;
                color: darken($thrive-orange, 10%);
            }
            &,
            .delivery-disabled & {
                background: rgba(122, 193, 67, 0.2);
                border-color: $thrive-green;
                color: darken($thrive-green, 10%);
            }
        }
        &:focus {
            outline: none;
        }
        &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
    .option-name {
        text-align: left;
        font-size: 14px;
    }
    .retail-price, .delivery-price {
            text-align: left;
            font-size: 16px;
            padding: 4px 0px;
    }
    .outOfStock {
        font-size: 10px;
        color: #da505a;
    }
    .retail-servings, .delivery-servings {
        font-size: 10px;
    }
}

.swatch-attribute {
    .tooltip-wrapper {
        padding: 0.5rem 0.75rem;
        text-align: left;
        display: block;
        border: 1px solid #cccccc;
        background: #f2f2f2;
        border-radius: 0.25rem;
        .swatch-option.text{
            padding: 0;
            border: none;
            background: none;
            border-radius: 0;
        }
    }
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
    background: rgb(240, 240, 240);
    color: rgb(148, 148, 148);
}


.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
    color: black;
    background: #fff;
    border: 1px solid #fff;
}

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    outline: 2px solid #ee0000;
    border: 1px solid #fff;
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
