// Bootstrap core
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";

// Thrive Primary colors
$thrive-green: #7ac143;
$thrive-dark-gray: #444444;
$thrive-orange: #f6a81c;

// Thrive Secondary Colors
$thrive-gray: #cccccc;
$thrive-light-gray: #eeeeee;
$thrive-purple: #a55784;
$thrive-salmon: #da505a;
$thrive-blue: #3fb8c1;
$thrive-blue-contrast: hsl(184, 51%, 33%);
$thrive-dark-yellow: #ffcb2b;
$thrive-transparent: transparent;
$thrive-q: #ef4a25;
$thrive-white: #fff;
$thrive-medium-gray: hsl(0, 0%, 80%);
$thrive-light-gray: hsl(0, 0%, 93%);
$thrive-black: #000;
$twitter: #00c3f3;
$facebook: #3b5998;
$google: #db3236;

// Green brand colors
$thrive-brand-green: $thrive-green;
$link-color-green: $thrive-green;

// Yellow brand colors colors
$thrive-brand-yellow: $thrive-orange;
$link-color-yellow: darken($thrive-brand-yellow, 5%);

// Define final brand colors
$blue: $thrive-blue;
$purple: $thrive-purple;
$red: $thrive-salmon;
$green: $thrive-green;
$light: hsl(0, 0%, 96%);
$dark: $thrive-dark-gray;

$primary: $thrive-green;
$secondary: #606060;
$success: $thrive-green;
$info: $thrive-blue;
$warning: $thrive-orange;
$danger: $thrive-salmon;

// Ideally we shouldn't actually use our brand color for UI elements as it does
// not have acceptable contrast with white text/bg, but this "allows" it:
$yiq-contrasted-threshold: 165;

$link-color: $thrive-blue-contrast;
$link-hover-color: darken($link-color, 5%);
$input-btn-focus-color: rgba($thrive-blue, .25);
$component-active-bg: $thrive-blue-contrast;

// Typography
$font-family-sans-serif: 'proxima-nova', 'Open Sans', sans-serif;
$headings-font-family: 'proxima-soft', 'Varela Round', sans-serif;
$headings-font-weight: 400;

$yamm-content-padding: 1rem;

// More closely match our old styles
$enable-shadows: true;

// Support our custom form control styles
$custom-control-indicator-size: 2.25rem;
$custom-control-indicator-checked-bg: $thrive-green;
$custom-control-indicator-active-bg: lighten($thrive-green, 35%);
$custom-checkbox-indicator-indeterminate-bg: $thrive-green;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='none' stroke='#fff' stroke-width='2'/></svg>");

// Initialize Bootstrap variables with our defaults above
@import "../../node_modules/bootstrap/scss/variables";
