//
//  Variables
//  _____________________________________________

$checkout-payment-method-title__border         : $checkout-shipping-method__border !default;
$checkout-payment-method-title__padding        : $checkout-shipping-method__padding !default;
$checkout-payment-method-title-mobile__padding : 15px !default;

$checkout-payment-method-content__padding__xl  : 22px !default;

$checkout-billing-address-details__line-height : 27px !default;
$checkout-billing-address-details__padding     : 0 0 0 23px !default;
$checkout-billing-address-form__max-width      : $checkout-shipping-address__max-width !default;

//
//  Common
//  _____________________________________________

.checkout-payment-method {
    line-height: 1.5;
    .payment-method {
        margin-top: 2rem;
        @include min-screen($screen__l) {
            margin: 2rem 0;
        }
        &._active {
            .payment-method-title {
                label:after {
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    background: $thrive-green;
                }
            }
            .payment-method-content {
                display: block;
            }
        }

        &-title {
            font-size: 1rem;
            display: block;
            input {
                position: absolute;
                opacity: 0;
                cursor: none;
                pointer-events: none;
            }
            label {
                position: relative;
                display: block;
                padding-left: 4rem;
                margin-bottom: 0;
                cursor: pointer;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                    border: 1px solid $thrive-gray;
                    cursor: pointer;
                }
            }
        }
        &-content {
            display: none;
            padding-left: 4rem;
            margin-top: 1rem;
            > .actions-toolbar {
                > .primary {
                    .action {
                        &.primary {
                            @extend .abs-button-l;
                        }
                    }
                }
            }
        }
        &.stripe-payments {
            .payment-method-title {
                label {
                    display: flex;
                    flex-direction: column;
                    @include min-screen($screen__l) {
                        justify-content: flex-start;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 1rem;
                    }
                    .stripe-icon-container {
                        display: block;
                        float: unset;
                        position: static;
                        top: 0;
                        order: 2;
                        @include min-screen($screen__l) {
                            margin-left: 1rem;
                        }
                        > span {
                            display: flex;
                            margin-top: 0.325rem;
                            @include min-screen($screen__l) {
                                margin-top: 0;
                            }
                        }
                        img.stripe-icon {
                            width: auto;
                            top: 0;
                            position: relative;
                            height: 1.25rem;
                            @include min-screen($screen__l) {
                                height: 1.7rem;
                            }
                        }
                    }
                }
            }
            .stripe-elements-field {
                box-shadow: none;
                display: block;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                &#stripe-payments-card-number {
                    width: 240px;
                }
            }
            .stripe-payments-brand {
                top: -17px;
                margin-left: -55px;
            }
            .stripe-payments-saved-card {
                .exp {
                    float: unset;
                    margin-left: 0.325rem;
                }
            }
        }
        .actions-toolbar {
            margin-top: 2rem;
            margin-left: -4rem;
            @include min-screen($screen__m) {
                margin-left: 0;
            }
        }
    }

    .payment-group {
        & + .payment-group {
            .step-title {
                margin: $indent__base 0 0;
            }
        }
    }

    .field-select-billing,
    .billing-address-form {
        @include lib-css(max-width, $checkout-billing-address-form__max-width);
    }

    .billing-address-same-as-shipping-block {
        margin: 0 0 $indent__s;
    }

    .checkout-billing-address {
        margin: 0 0 $indent__base;

        .primary {
            .action-update {
                margin-right: 0;
            }
        }

        .action-cancel {
            @extend .abs-action-button-as-link;
        }

        .billing-address-details {
            @include lib-css(padding, $checkout-billing-address-details__padding);
        }
    }

    .payment-method-note {
        & + .checkout-billing-address {
            margin-top: $indent__base;
        }
    }

    .field-select-billing {
        > .label {
            @extend .abs-visually-hidden;
        }
    }

    .payment-method-iframe {
        background-color: transparent;
        display: none;
        width: 100%;
    }

    .no-payments-block {
        margin: $indent__base 0;
    }

    .payments {
        .legend {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .actions-toolbar {
            .primary {
                float: right;
                margin: 0;
            }
        }

        .fieldset {
            > .field-select-billing {
                > .control {
                    float: none;
                    width: 100%;
                }
            }
        }

        .payment-method-content {
            .fieldset {
                > .field {
                    margin: 0 0 $indent__base;

                    &.type {
                        .control {
                            margin-left: 25.8%;
                        }

                        &.no-detection {
                            .control {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-billing-address {
        .action-update {
            float: right;
        }

        .actions-toolbar {
            .action-cancel {
                margin: 6px $indent__base 0 0;
            }
        }
    }
}
