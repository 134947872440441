.long-category-page {
    .breadcrumbs{
        display: none;
    }
}
.category-header-menu {
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    padding: 4px 15px;
    gap: 10px;
    position: sticky;
    top: 86px;
    z-index: 3;
    .category-header-select {
        display: flex;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .category-header-options {
            color: #29797f !important;
            text-decoration: underline;
            &.active {
                color: black !important;
                box-shadow: unset !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        top: 116px;
    }

}
.category-section {
    .category-description {
        padding: 10px;
        margin-bottom: 0px;
    }
    .category-cms {
        padding: 10px;
    }
}
.long-category-wrapper {
    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h2 {
            margin: 0;
        }
        .links {
            display: flex;
            gap: 15px;
            align-items: center;
        }
    }
}
@include max-screen($screen__m) {
    .long-category-wrapper {
        .title {
            .links {
                .back-to-top {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
