.footer {
    &-container {
        a {
            color: #666;
            font-size: 14px;
            &:hover,
            &:focus,
            &:active {
                color: $thrive-black;
            }
        }
    }
    &-top {
        margin-bottom: 0.25rem;
        padding: 1rem;
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: center;
            padding: 0;
        }
        background: lighten($thrive-gray, 5%);
        div {
            padding: 0.25rem 0;
            @include media-breakpoint-up(md) {
                padding: 1rem 2rem;
                border-right: 4px solid $thrive-white;
                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }
    &-bottom {
        background: $thrive-light-gray;
        padding: 0;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        > div {
            padding: 1rem;
            @include media-breakpoint-up(lg) {
                padding: 2rem;
                margin-right: 2rem;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        ul {
            list-style: none;
            padding: 0;
        }
        li {
            padding: 0.25rem 0;
            margin-bottom: 0;
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
    &-header {
        font-weight: bold;
    }
    .socials {
        margin-bottom: 1rem;
        a {
            display: inline-block;
            padding: 0 12px 10px;
            padding-top: 0;
            font-size: 1.5rem;
        }
    }
}

.amgdprjs-bar-template {
    position: sticky;
    .amgdprcookie-bar-container {
        @include media-breakpoint-up(lg) {
            padding: 2em 10rem;
        }
        .amgdprcookie-bar-block {
            text-align: center;
        }
        .amgdprcookie-buttons-block {
            justify-content: center;
            .amgdprcookie-button.-save {
                flex-basis: auto;
            }
            .amgdprcookie-button.-settings {
                flex-basis: auto;
            }
        }

    }
}

.modal-dialog{
    .modal-content {
        .amgdprcookie-cookie-container {
            @include media-breakpoint-down(sm) {
                padding-bottom: 1rem;

                .amgdprcookie-text{
                    margin-bottom: 5px;
                }
            }
            .amgdprcookie-options {
                border-radius: 6px;
                margin-left: 15px;
                width: 9rem;
                @include media-breakpoint-down(sm) {
                    padding-right: 2.5rem;
                }
            }
        }
    }
}

.amgdprcookie-table {
    thead {
        display: none;
    }
    tr {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto auto auto;
        border-bottom: 1px solid lightgray;
        .amgdprcookie-cell.-name::before {
            content: "\a Cookie Name: \a";
            font-weight: bold;
            white-space: pre;
        }
        .amgdprcookie-cell.-name::after {
            content:"\a Cookie Provider: \a";
            font-weight: bold;
            white-space: pre;
        }
        .amgdprcookie-cell.desc::before {
            content: "Cookie Description: \a";
            font-weight: bold;
            white-space: pre;
        }
        .amgdprcookie-cell.-lifetime::before {
            content: "Cookie Lifetime: \a";
            font-weight: bold;
            white-space: pre;
        }
        .amgdprcookie-cell.-lifetime::after {
            content: "\a Cookie Type: \a";
            font-weight: bold;
            white-space: pre;
        }
    }
}
