//
//  Variables
//  _____________________________________________

$checkout-shipping-address__max-width           : 600px !default;
$checkout-shipping-address__margin-top          : 28px !default;

$checkout-shipping-item-icon__color             : $color-white !default;
$checkout-shipping-item-icon__content           : $icon-checkmark !default;

$checkout-shipping-item__border                 : 2px solid transparent !default;
$checkout-shipping-item__line-height            : 30px !default;
$checkout-shipping-item__margin                 : 0 0 $indent__base !default;
$checkout-shipping-item__padding                : $indent__base ($indent__l + 5px) $indent__base $indent__base !default;
$checkout-shipping-item__transition             : 0.3s border-color !default;
$checkout-shipping-item__width                  : 100% / 3 !default;
$checkout-shipping-item-tablet__width           : 100% / 2 !default;
$checkout-shipping-item-mobile__width           : 100% !default;
$checkout-shipping-item__active__border-color   : $thrive-green !default;
$checkout-shipping-item-icon__background-color  : $checkout-shipping-item__active__border-color !default;

$checkout-shipping-item-icon__selected__height  : 27px !default;
$checkout-shipping-item-icon__selected__width   : 29px !default;

$checkout-shipping-item-mobile__padding         : 0 0 15px !default;
$checkout-shipping-item-mobile__margin          : $checkout-shipping-item-mobile__padding !default;
$checkout-shipping-item-mobile__active__padding : 15px ($indent__l + 5px) 15px 18px !default;

$checkout-shipping-item-before__border-color    : $color-gray80 !default;
$checkout-shipping-item-before__height          : calc(100% - 20px) !default;

$checkout-shipping-method__border               : $checkout-step-title__border !default;
$checkout-shipping-method__padding              : $indent__base !default;

//
//  Common
//  _____________________________________________

.opc-wrapper {

    //
    //  Shipping Address
    //  ---------------------------------------------

    .form-login,
    .form-shipping-address {
        @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        margin-bottom: $indent__base;

        .fieldset {
            .note {
                font-size: $font-size__base;
                margin-top: $indent__s;
            }
        }
    }

    .field {
        &.addresses {
            @extend .abs-add-clearfix;
        }
    }

    .action-show-popup {
        margin: 0 0 $indent__base;

        > span {
            &:before {
                content: '+';
                padding-right: $indent__xs;
            }
        }
    }
}

//
//  Shipping Methods
//  ---------------------------------------------

.checkout-shipping-method {
    .step-title {
        margin-bottom: 0;
    }

    .no-quotes-block {
        margin: $indent__base 0;
    }
}

.methods-shipping {
    .actions-toolbar {
        .action {
            &.primary {
                @extend .abs-button-l;
                margin: $indent__base 0 0;
            }
        }
    }
}

.table-checkout-shipping-method {
    thead {
        th {
            display: none;
        }
    }

    tbody {
        td {
            @include lib-css(border-top, $checkout-shipping-method__border);
            @include lib-css(padding-bottom, $checkout-shipping-method__padding);
            @include lib-css(padding-top, $checkout-shipping-method__padding);

            &:first-child {
                padding-left: 0;
                padding-right: 0;
                width: 20px;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: none;
                }
            }
        }
        .row-error {
            td {
                border-top: none;
                padding-bottom: $indent__s;
                padding-top: 0;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .form-login {
            @include lib-css(border-bottom, 1px solid $checkout-shipping-item-before__border-color);
            @include lib-css(margin, $checkout-shipping-item-mobile__padding);
            @include lib-css(padding, $checkout-shipping-item-mobile__padding);
        }

        .form-login,
        .form-shipping-address {
            @include lib-css(margin-top, $checkout-shipping-address__margin-top);
        }

        .action-show-popup {
            width: 100%;
        }

        .edit-address-link {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size         : 28px,
                $_icon-font-line-height  : 28px,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $color-gray19,
                $_icon-font-color-hover  : $color-gray19,
                $_icon-font-color-active : $color-gray19
            );
            margin: 0;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-shipping-method {
        .actions-toolbar {
            > .primary {
                float: right;
            }

            .action {
                &.primary {
                    margin: 0;
                }
            }
        }
    }

    .opc-wrapper {
        .form-login,
        .form-shipping-address {
            @include lib-css(max-width, $checkout-shipping-address__max-width);
        }
    }
    .table-checkout-shipping-method {
        width: auto;
    }
}


//
// Thrive Theme
// ----------------------------------------------

.opc-wrapper {
    .form-login,
    .form-shipping-address {
        margin-top: 0;
        margin-bottom: 0;
        max-width: unset;
    }
    .checkout-account-fields {
        display: flex;
        flex-direction: column;
        @include min-screen($screen__m) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: {
                left: -0.5rem;
                right: -0.5rem;
            }
        }
        .fieldset {
            margin-bottom: 1rem;
            @include min-screen($screen__m) {
                width: 50%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }
        }
        .checkout-account-login {
            margin-left: 0;
            margin-top: 1rem;
            .primary {
                float: unset;
            }
        }
    }
}

.fieldset {
    &.address {
        display: flex;
        flex-direction: column;
        @include min-screen($screen__m) {
            flex-direction: row;
            flex-wrap: wrap;
            margin: {
                left: -0.5rem;
                right: -0.5rem;
            }
        }
        .field:not(fieldset) {
            margin-bottom: 1rem;
            @include min-screen($screen__m) {
                width: 50%;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }
        }
        fieldset.field.street {
            width: 100%;
            .control {
                width: 100%;
                @include min-screen($screen__m) {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
        legend.label {
            display: none;
        }
        .field.street .field.additional .label {
            position: static;
            width: 100%;
            height: auto;
            margin: 0;
            margin-bottom: 0.5rem;
            &:after {
                content: '(optional)';
            }
        }
    }
}

.field-error {
    width: 100%;
    color: #da505a;
    font-size: 0.75rem;
}

.checkout-shipping-address {
    &.shipping-address-choose {
        .step-title {
            display: none;
        }
    }
}

.shipping-address-items {
    &-choose-heading {
        display: none;
        .shipping-address-choose & {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $thrive-light-gray;
        }
        h3 {
            font-size: 1.5rem;
            font-weight: 300;
            margin: 0;
        }
    }
}

.shipping-address-item {
    position: relative;
    display: none;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    &:hover {
        .shipping-address-item-select {
            background: $thrive-gray;
        }
    }
    .shipping-address-choose & {
        display: flex;
    }
    &.selected-item {
        display: flex;
        align-items: flex-start;
        .shipping-address-choose & {
            align-items: center;
        }
        .shipping-address-item-select {
            &:after {
                content: '';
                position: absolute;
                top: 8px;
                left: 8px;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background: $thrive-green;
            }
        }
    }
    &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    .shipping-address-item-select {
        position: relative;
        height: 36px;
        width: 36px;
        display: none;
        border-radius: 50%;
        background: $thrive-white;
        border: 1px solid $thrive-gray;
        padding: 0;
        margin-right: 2rem;
        .shipping-address-choose & {
            display: block;
        }
    }
    &-address {
        flex: 1;
    }
    &-block {
        display: block;
        .shipping-address-choose & {
            display: inline;
        }
    }
    &-change-link {
        .shipping-address-choose & {
            display: none;
        }
    }
}

.new-address-popup {
    display: none;
    .shipping-address-choose & {
        display: block;
        margin-top: 2rem;
    }
    .action {
        margin-bottom: 0;
    }
}

.checkout-shipping-method {
    .radio {
        font-size: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        &:first-of-type {
            margin-top: 2rem;
        }
    }
}

